export interface Condition {
    field: ConditionField,
    operator: ConditionOperator,
    value: number,
}

export enum ConditionField {
    AMOUNT = 'amount',
}

export enum ConditionOperator {
    EQUAL = '==',
    GREATER_THAN = '>',
    LESS_THAN = '<',
}

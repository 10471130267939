import { Button } from "react-bootstrap";
import { useText } from "../../contexts/TextContext"
import useQueryParam from "../../hooks/useQueryParams";
import NeonText from "./NeonText";
import { STRAVA_CLIENT_ID } from "../../utils/shared/constants";
import { getRestaurantFullUrl } from "../../utils/restaurant";
import { CUSTOMER_NAV } from "../../constants/routes";
import { useRestaurant } from "../../contexts/RestaurantContext";
import Restaurant from "../../models/Restaurant";

const SCOPES = 'read,activity:read_all';


const getStravaAuthUrl = (restaurant: Restaurant) => {
    const redirectUri = getRestaurantFullUrl(restaurant, `/${CUSTOMER_NAV.MY_BENEFITS}`);

    const stravaAuthUrl = `https://www.strava.com/oauth/authorize?client_id=${STRAVA_CLIENT_ID}&redirect_uri=${redirectUri}&response_type=code&scope=${SCOPES}`;
    return stravaAuthUrl;
}

export const StravaConnect = () => {
    const text = useText();
    const { debug } = useQueryParam();
    const restaurant = useRestaurant();

    const stravaAuthUrl = getStravaAuthUrl(restaurant);

    return <div>
        <h3 className='m-0'>{text.activity}</h3>
        <p className="text-muted small">{text.activityDescription}</p>

        {debug
            ? <>
                <a href={stravaAuthUrl}>
                    <Button style={{ background: '#fc4c02', border: '#fc4c02' }}>
                        {text.connectStrava}
                    </Button>
                </a>
            </>
            : <NeonText text={text.comingSoon} />}
    </div>
}

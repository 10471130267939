
interface Deal {
    id: string,
    type: DealType,
    usedCount: number,

    discount?: number,
    code?: string,

    requiredCount?: number,
    validProducts?: string[],

    discountPerItem?: Record<string, DealItemDiscount>,
};

interface DealItemDiscount {
    amount: number,
    pointsSpend?: number,
}

export default Deal;

export enum DealType {
    WHOLE_CART_PERCENTAGE = 'whole-cart-percentage',
    STAMP_CARD = 'stamp-card',
    LOYALTY_POINTS = 'loyalty-points',
}

export const DealTypeLabelKeys: Record<DealType, 'percentageCode' | 'stampCard' | 'loyaltyPoints'> = {
    [DealType.WHOLE_CART_PERCENTAGE]: 'percentageCode',
    [DealType.STAMP_CARD]: 'stampCard',
    [DealType.LOYALTY_POINTS]: 'loyaltyPoints',
}

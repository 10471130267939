import React, { useEffect } from 'react'
import { useText } from '../../contexts/TextContext'
import { useForm, SubmitHandler } from "react-hook-form";
import Deal, { DealType, DealTypeLabelKeys } from '../../models/Deal';
import { addDoc, collection, doc, setDoc, } from 'firebase/firestore';
import { useDB } from '../../contexts/FirebaseContext';
import { Collections } from '../../utils/shared/constants';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { toFirebaseDoc } from '../../utils/shared/firebase';
import { toast } from 'react-toastify';
import RestaurantLink from '../RestaurantLink';
import { STAFF, STAFF_NAV } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';
import { getRestaurantPath } from '../../utils/restaurant';
import { useProducts } from '../../contexts/ProductsContext';

type Inputs = {
    code: string,
    discount: number,
    type: DealType,
    requiredCount: number,
    validProducts: string[],
};

interface Props {
    deal?: Deal;
}

export default function DealForm(props: Props) {
    const text = useText();
    const db = useDB();
    const restaurant = useRestaurant();
    const { register, handleSubmit, formState: { errors }, watch, reset } = useForm<Inputs>();
    const navigate = useNavigate();
    const back = getRestaurantPath(restaurant, `/${STAFF}/${STAFF_NAV.DEALS}`);
    const productsHolder = useProducts();

    const type = watch('type');

    useEffect(() => {
        const defaultValues: Partial<Inputs> = {};
        if (props.deal) {
            defaultValues.type = props.deal.type;

            if (props.deal.type === DealType.WHOLE_CART_PERCENTAGE) {
                defaultValues.discount = props.deal.discount;
                defaultValues.code = props.deal.code;
            } else {
                defaultValues.requiredCount = props.deal.requiredCount;
                defaultValues.validProducts = props.deal.validProducts;
            }

        }

        reset({ ...defaultValues });
    }, [props.deal, reset]);

    const onSubmit: SubmitHandler<Inputs> = async data => {
        console.log('d', data);
        if (props.deal) {
            const newDeal: Deal = {
                ...props.deal,
                ...data,
            };

            try {
                await setDoc(doc(db, Collections.restaurants, restaurant.id, Collections.deals, newDeal.id), toFirebaseDoc(newDeal));
                toast.success(text.successDefault);
                navigate(back);
            } catch (e) {
                console.error('Failed', e);
                toast.error(text.errorDefault);
            }
        } else {
            const newDeal: Deal = {
                ...data,
                id: '',
                usedCount: 0,
            };

            try {
                await addDoc(collection(db, Collections.restaurants, restaurant.id, Collections.deals), toFirebaseDoc(newDeal));
                reset({ type: data.type });
                toast.success(text.successDefault);
            } catch (e) {
                console.error('Failed', e);
                toast.error(text.errorDefault);
            }
        }
    }


    const renderPerTypeFields = () => {
        if (type === DealType.WHOLE_CART_PERCENTAGE) {
            return <>
                <label>{text.code}</label>
                <input type="text" className={"form-control" + (errors.code ? " is-invalid" : "")}
                    {...register("code", { required: true, minLength: 4 })} />

                <label>{text.discount} [%]</label>
                <input type="number" className={"form-control" + (errors.discount ? " is-invalid" : "")}
                    {...register("discount", { required: true, min: 0, max: 100, valueAsNumber: true })}
                />
            </>
        } else {
            return <>
                <label>{text.howManyStampsNeeded}</label>
                <input type="number" className={"form-control" + (errors.requiredCount ? " is-invalid" : "")}
                    {...register("requiredCount", { required: true, min: 1, max: 100, valueAsNumber: true })}
                />

                <label>{text.validProducts}</label>
                {productsHolder.categories?.map(category => <div key={category}>
                    <h6>{category}</h6>
                    <div className='row'>
                        {productsHolder.productsByCategory[category].map(product => <div className='col-4' key={product.id}>
                            <input type="checkbox" className={"form-check-input me-2" + (errors.validProducts ? " is-invalid" : "")}
                                {...register("validProducts", { required: true })}
                                value={product.id}
                            />
                            <label className='form-check-label'>{product.name}</label>
                        </div>)}
                    </div>
                </div>)}
            </>
        }
    }

    return (
        <div>
            <h4 className="mb-0">{text.newDeal}</h4>
            <form className="" onSubmit={handleSubmit(onSubmit)}>
                <label>{text.type}</label>
                <select className='form-control' {...register('type')}>
                    {[DealType.WHOLE_CART_PERCENTAGE, DealType.STAMP_CARD].map(type =>
                        <option key={type} value={type}>{text[DealTypeLabelKeys[type]]}</option>
                    )}

                </select>

                {renderPerTypeFields()}

                <div className='d-flex mt-3 '>
                    {props.deal && <RestaurantLink to={back}>

                        <button className="btn btn-secondary " type='button'>
                            {text.cancel}
                        </button>
                    </RestaurantLink>}
                    <button className="btn btn-primary  ms-auto">
                        {props.deal ? text.update : text.add}
                    </button>
                </div>
            </form>
        </div >
    )
}

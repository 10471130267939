import { Cart, CartItem } from "../../models/Cart";
import Deal, { DealType } from "../../models/Deal";
import { Order, OrderState } from "../../models/Order";
import Product from "../../models/Product";
import Restaurant from "../../models/Restaurant";
import UserDealTracker from "../../models/UserDealTracker";
import { DEFAULT_SERVE_TIME, FEATURE } from "./constants";
import { calculateVatPart } from "./converters";

interface CartSum {
    perVatRateVatAmount: Record<number, number>
    perVatSums: Record<number, number>,
    itemsSum: number,
    discount: number,
    sum: number
    vat: number,
    netto: number,
    tip: number,
    appliedDeals: Deal[],
}

export const DEFAULT_VAT_RATE = 12;

interface SumCartOptions {
    avaiblePoints?: {
        pointsBalance: number,
        pointsValueDivider: number | undefined,
    }
}

export const sumCart = (cart: Cart | Order, options: SumCartOptions = {}): CartSum => {
    const items = cart?.items || [];
    const discountsPerItem = _calculateDiscountPerItem(cart, items, options);
    let sum = 0; let itemsSum = 0; let vatSum = 0; let discountSum = 0;
    const perVatRateVatAmount: Record<number, number> = {};
    const perVatRateSums: Record<number, number> = {};
    const appliedDeals: Deal[] = [...cart?.deals || []].filter(deal => deal.type !== DealType.LOYALTY_POINTS);

    for (const item of cart?.items || []) {
        const itemSum = item.quantity * item.itemPrice;
        const itemDiscountRecord = discountsPerItem[item.itemId];
        const discount = itemDiscountRecord?.amount || 0;
        const discountedSum = itemSum - discount;
        const vatRate = Number(item.vatRate) || DEFAULT_VAT_RATE;
        const vat = calculateVatPart(discountedSum, vatRate);
        if (itemDiscountRecord) {
            const existingDeal = appliedDeals.find(deal => deal.id === itemDiscountRecord.dealId);
            if (existingDeal) {
                existingDeal.discountPerItem = existingDeal.discountPerItem || {};
                existingDeal.discountPerItem[item.itemId] = { amount: discount, pointsSpend: itemDiscountRecord.pointsSpend };
                if (itemDiscountRecord.type === DealType.LOYALTY_POINTS) {
                    existingDeal.discount = (existingDeal.discount || 0) + (itemDiscountRecord.pointsSpend || 0);
                }
            } else {
                appliedDeals.push({
                    id: itemDiscountRecord.dealId,
                    type: itemDiscountRecord.type,
                    discount: itemDiscountRecord.pointsSpend || 0,
                    discountPerItem: {
                        [item.itemId]: { amount: discount, pointsSpend: itemDiscountRecord.pointsSpend },
                    },
                    usedCount: 0,
                })
            }
        }


        sum += discountedSum;
        itemsSum += itemSum;
        vatSum += vat;
        discountSum += discount;
        if (!perVatRateSums[vatRate]) {
            perVatRateSums[vatRate] = 0;
            perVatRateVatAmount[vatRate] = 0;
        }
        perVatRateSums[vatRate] += discountSum;
        perVatRateVatAmount[vatRate] += vat;
    }

    const netto = sum - vatSum;
    let tip = 0;
    if (cart?.tip) {
        tip = Math.round((sum / 100) * cart.tip)
        sum += tip;
    }


    return {
        perVatRateVatAmount,
        perVatSums: perVatRateSums,
        itemsSum,
        discount: discountSum,
        tip,
        sum,
        vat: vatSum,
        netto,
        appliedDeals,
    }
};

export const loyaltyPointsEarn = (sumAmount: number, restaurant: Restaurant): number => {
    const divider = restaurant.features?.[FEATURE.LOYALTY_POINTS_GAIN_DIVIDER] || 0;
    if (!divider) {
        return 0;
    }
    return Math.floor(sumAmount / 100 / divider);
}

interface ItemDiscount {
    amount: number,
    type: DealType,
    pointsSpend: number,
    dealId: string,
}
const _calculateDiscountPerItem = (cart: Cart | Order, items: CartItem[], options: SumCartOptions) => {
    const deals = cart?.deals || [];
    const discountsPerItem: Record<string, ItemDiscount> = {};
    const itemsSortedByPrice = [...items];
    itemsSortedByPrice.sort((a, b) => b.itemPrice - a.itemPrice);


    for (const deal of deals) {
        if (deal.type === DealType.WHOLE_CART_PERCENTAGE) {
            for (const item of items) {
                const itemValue = item.quantity * item.itemPrice;
                const discountPerc = deal.discount || 0;
                const discountAmount = Math.round(itemValue * discountPerc / 100)
                discountsPerItem[item.itemId] = {
                    dealId: deal.id,
                    type: deal.type,
                    amount: discountAmount,
                    pointsSpend: 0,
                }
            }
        } else if (deal.type === DealType.STAMP_CARD) {
            const validProducts = deal.validProducts || [];
            const itemsWithApplicableProduct = itemsSortedByPrice
                .filter(item => validProducts.includes(item.productId))

            let remainingFreeItems = deal.discount || 0;
            let currentItemIx = 0;
            while (remainingFreeItems > 0) {
                const item = itemsWithApplicableProduct[currentItemIx];
                if (!item) {
                    break;
                }
                const freeItemQuantity = Math.min(remainingFreeItems, item.quantity);
                const discountAmount = freeItemQuantity * item.itemPrice;

                discountsPerItem[item.itemId] = {
                    dealId: deal.id,
                    type: deal.type,
                    amount: discountAmount,
                    pointsSpend: 0,
                };

                remainingFreeItems -= freeItemQuantity;
                currentItemIx++;
            }
        } else if (deal.type === DealType.LOYALTY_POINTS) {
            const dealDiscountPerItem = deal.discountPerItem || {};
            for (const item of items) {
                if (!dealDiscountPerItem[item.itemId]) {
                    continue;
                }
                discountsPerItem[item.itemId] = {
                    dealId: deal.id,
                    type: deal.type,
                    amount: dealDiscountPerItem[item.itemId].amount,
                    pointsSpend: dealDiscountPerItem[item.itemId].pointsSpend || 0,
                }
            }
        }
    }

    if (options.avaiblePoints?.pointsValueDivider) {
        let remainingPointsValue = Math.floor(options.avaiblePoints.pointsBalance / options.avaiblePoints.pointsValueDivider) * 100
        for (const item of itemsSortedByPrice) {
            if (remainingPointsValue < item.itemPrice) {
                continue;
            }

            const maxQuantity = Math.floor(remainingPointsValue / item.itemPrice)
            const discountQuantity = Math.min(maxQuantity, item.quantity);
            const discountAmount = discountQuantity * item.itemPrice;
            const pointsSpend = Math.ceil((discountAmount / 100) * options.avaiblePoints.pointsValueDivider)

            discountsPerItem[item.itemId] = { dealId: DealType.LOYALTY_POINTS, type: DealType.LOYALTY_POINTS, amount: discountAmount, pointsSpend }

            remainingPointsValue -= discountAmount;
        }
    }

    return discountsPerItem;
}

export const productPrice = (product: Product, choices: string[]) => {
    let price = product.basePrice;

    for (const option of product.options || []) {
        for (const choice of option.choices || []) {
            if (choices.includes(choice.id)) {
                price += choice.priceAdjustment || 0;
            }
        }
    }

    return price;
};

export const generateItemId = (productId: string, choices: string[]) => {
    const sorted = [...choices].sort();
    return productId + '|' + JSON.stringify(sorted);
};

export const generateItemName = (product: Product, choices: string[]) => {
    let name = product.name;

    if (choices.length) {
        const allChoices = (product.options || []).map(option => (option.choices || [])).flat();
        const abbreviations = allChoices
            .filter(choice => choices.includes(choice.id))
            .map(choice => choice.name.length > 0 ? choice.name.substr(0, 1) : 'x')
            .map(abbreviation => `${abbreviation}.`)
            .join(' ');
        name = `${name} (${abbreviations})`;
    }

    return name;
};

export const areAllMandatoryChoosen = (product: Product, choices: string[]): boolean => {
    const mandatoryOptions = (product.options || []).filter(option => option.mandatory);
    for (const option of mandatoryOptions) {
        const found = (option.choices || []).find(choice => choices.includes(choice.id));

        if (!found) {
            return false;
        }
    }


    return true;
};

export const getProductDefaults = (product: Product): string[] => {
    const defaults = product.options?.map(option =>
        (option.choices || [])
            .filter(choice => choice.default)
            .map(choice => choice.id)

    ).flat() || [];
    return defaults;
}

export const calculateStampDealIncrease = (cart: Cart | Order, deals: Deal[]): Record<string, number> => {
    const result: Record<string, number> = {};

    if (!cart.uid) {
        // No stamps for anonymous users
        return result;
    }

    for (const deal of deals) {
        if (deal.type !== DealType.STAMP_CARD) {
            continue;
        }
        const dealIncrease = cart.items
            .filter(item => deal.validProducts?.includes(item.productId))
            .map(item => item.quantity)
            .reduce((sum, current) => sum + current, 0);
        if (!dealIncrease) {
            continue;
        }

        result[deal.id] = dealIncrease;
    }

    return result;
}

export const calculateDealTrackerUpdate = (oldDealTracker: UserDealTracker, deal: Deal, increase: number) => {
    const newDealTracker = { ...oldDealTracker };

    const stampsPerCardIncludingFree = (deal.requiredCount || 0) + 1;

    if (increase + newDealTracker.stampCount >= stampsPerCardIncludingFree) {
        newDealTracker.filledCards += 1;
        newDealTracker.stampCount = 0;
    } else {
        newDealTracker.stampCount += increase;
    }

    return newDealTracker;
}

interface DealsUpdate {
    deals: Deal[],
    changed: boolean
}
export const maybeUpdateStampDealsOnCart = (oldDeals: Deal[], freeItemCount: number, deal: Deal): DealsUpdate => {
    const discountOnOrder = oldDeals.find(_deal => _deal.id === deal.id);


    let changed = false;
    let newDeals: Deal[] = [...oldDeals];
    if (freeItemCount) {
        if (discountOnOrder) {
            if (discountOnOrder.discount !== freeItemCount) {
                discountOnOrder.discount = freeItemCount;
                changed = true;
            }
        } else {
            newDeals.push({
                ...deal,
                discount: freeItemCount,
            })
            changed = true;
        }
    } else {
        if (discountOnOrder) {
            newDeals = newDeals.filter(_deal => _deal.id !== deal.id)
            changed = true;
        }
    }

    return {
        deals: newDeals,
        changed,
    }
}

export const sortCartItems = (cartItems: CartItem[], allProducts: Product[], categoryOrder: string[]): CartItem[] => {
    const sorted = [...cartItems];

    sorted.sort((a, b) => {
        const productA = allProducts.find(product => product.id === a.productId);
        const productB = allProducts.find(product => product.id === b.productId);

        if (!productA || !productB) {
            return 0;
        }

        const categoryA = productA.category || '';
        const categoryB = productB.category || '';

        const categoryIxA = categoryOrder.indexOf(categoryA);
        const categoryIxB = categoryOrder.indexOf(categoryB);

        if (categoryIxA < 0 && categoryIxB >= 0) {
            return 1;
        } else if (categoryIxA >= 0 && categoryIxB < 0) {
            return -1;
        } if (categoryIxA < categoryIxB) {
            return -1;
        } else if (categoryIxA > categoryIxB) {
            return 1;
        }

        const orderA = productA.order || 0;
        const orderB = productB.order || 0;

        if (orderA < orderB) {
            return -1;
        } else if (orderA > orderB) {
            return 1;
        }

        return productA.name.localeCompare(productB.name);
    });


    return sorted;
}

const MIN_TIME_REMAINING = 0;
export const calculateCartTimeEstimateInMinutes = (cart: Cart,
    restaurant: Restaurant,
    productsById: Record<string, Product>): number => {
    const itemEstimates = cart.items.map(item => calculateOrderItemEstimateInMinutes(item, restaurant, productsById));

    const totalEstimate = itemEstimates.reduce((sum, current) => sum + current, 0);

    return Math.max(MIN_TIME_REMAINING, totalEstimate)
}

export const calculateOrderRemainingTimeEstimateInMinutes = (order: Order, restaurant: Restaurant,
    productsById: Record<string, Product>): number => {
    const itemEstimates = order.items.map(item => calculateOrderItemEstimateInMinutes(item, restaurant, productsById));

    const totalEstimate = itemEstimates.reduce((sum, current) => sum + current, 0);

    if (order.state === OrderState.PAID) {
        return Math.max(MIN_TIME_REMAINING, totalEstimate)
    }

    const now = new Date();
    const updatedAt = new Date(order.updated || order.created);

    const diff = now.getTime() - updatedAt.getTime();
    const diffMinutes = Math.round(diff / 60000);

    const remaining = totalEstimate - diffMinutes;

    return Math.max(MIN_TIME_REMAINING, remaining);
}

export const calculateOrderItemEstimateInMinutes = (item: CartItem, restaurant: Restaurant, productsById: Record<string, Product>): number => {
    const category = productsById[item.productId]?.category || '';

    const timeToServe = restaurant.categoryServeTimes?.[category] || DEFAULT_SERVE_TIME;

    return item.quantity * timeToServe;
}

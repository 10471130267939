import { ArrowBackSVG } from '../../assets/svgs'
import RestaurantLink from '../RestaurantLink'
import Logo from '../Logo'
import { Dropdown } from 'react-bootstrap'
import { useText } from '../../contexts/TextContext'
import OpeningHoursModal from '../modals/OpeningHoursModal'
import { useState } from 'react'
import { useSettings } from '../../contexts/SettingsContext'
import { Language } from '../../models/Settings'
import { AccountLinesToggle } from '../ThreeDotsToggle'
import { useAuth } from '../../contexts/AuthContext'
import { Link } from 'react-router-dom'
import { CUSTOMER_NAV, DISCORD_LINK, STAFF } from '../../constants/routes'
import { useRestaurant } from '../../contexts/RestaurantContext'
import BusyIndicator, { useQueueData } from './BusyIndicator'
import useQueryParam from '../../hooks/useQueryParams'
import { ThreeLinesToggle } from '../ThreeDotsToggle'
import { useLastOrder } from '../../contexts/LastOrderContext'
import { OrderState } from '../../models/Order'
import LoyaltyPointsIndicator from './LoyaltyPointsIndicator'

interface Props {
    back?: string
    noBack?: boolean
    hideLastOrder?: boolean
}

export default function Menu(props: Props) {
    const text = useText();
    const { settings, updateSettings } = useSettings();
    const [showHours, setShowHours] = useState(false);
    const { currentUser, logout, showLoginModal } = useAuth()
    const restaurant = useRestaurant();
    const { publicTerminal } = useQueryParam();
    const { lastOrder } = useLastOrder();
    const { minutesToLatestOrder } = useQueueData();

    const toggleLanguage = () => {
        const newLanguage: Language = settings.language === 'english' ? 'swedish' : 'english';
        updateSettings({ ...settings, language: newLanguage })
    }

    const dropdownItems: JSX.Element[] = [];
    if (!publicTerminal) {
        if (currentUser) {
            dropdownItems.push(
                <Dropdown.Item as={RestaurantLink} to={`/${CUSTOMER_NAV.MY_ORDERS}`} key="orders">
                    <i className='bi bi-bag-check me-2' />{text.myOrders}
                </Dropdown.Item>,
                <Dropdown.Item as={RestaurantLink} to={`/${CUSTOMER_NAV.MY_BENEFITS}`} key="benefits">
                    <i className='bi bi-gift me-2' />{text.myBenefits}
                </Dropdown.Item>,
            )
        } else {
            dropdownItems.push(
                <Dropdown.Item className="" onClick={showLoginModal} key="login">
                    <i className='bi bi-person-fill me-2' />{text.login}
                </Dropdown.Item>
            )
        }
        dropdownItems.push(
            <Dropdown.Item className="" onClick={() => window.open(DISCORD_LINK, '_blank')} key="discord">
                <i className='bi bi-discord me-2' />{text.chatWithUs}
            </Dropdown.Item>,
        );

        dropdownItems.push(<Dropdown.Divider key="user-divider" />)
    }

    dropdownItems.push(
        <Dropdown.Item className="" onClick={() => setShowHours(true)} key="hours">
            <i className='bi bi-clock me-2' />{text.openingHours}
        </Dropdown.Item>,
        <Dropdown.Item className="" onClick={() => toggleLanguage()} key="language">
            <i className='bi bi-translate me-2' />{text.otherLanguage}
        </Dropdown.Item>
    );


    if (currentUser) {
        if (currentUser.isSuperAdmin || currentUser.staff === restaurant.id) {
            dropdownItems.push(<Dropdown.Divider key="general-divider" />)
            dropdownItems.push(<Dropdown.Item as={RestaurantLink} to={`/${STAFF}`} key="staff">
                <i className='bi bi-person-vcard me-2' />{text.staff}
            </Dropdown.Item>)
        }
        if (currentUser.isSuperAdmin) {
            dropdownItems.push(<Dropdown.Item as={Link} to={`/superAdmin`} key="superAdmin">
                <i className='bi bi-house-fill me-2' />{text.superAdmin}
            </Dropdown.Item>)
        }

        dropdownItems.push(<Dropdown.Divider key="logout-divider" />)
        dropdownItems.push(<Dropdown.Item className="" onClick={() => logout()} key="logout">
            <i className='bi bi-box-arrow-right me-2' />{text.logout}
        </Dropdown.Item>)
    }

    const rendetLastOrder = () => {
        if (props.hideLastOrder) {
            return <></>
        }

        if (!lastOrder || !lastOrder.updated) {
            return <></>
        }
        const recentCutDate = new Date();
        recentCutDate.setHours(recentCutDate.getHours() - 1);
        if (lastOrder.updated < recentCutDate) {
            return <></>
        }

        const showOnState = [OrderState.PAID, OrderState.IN_PROGRESS, OrderState.DONE];
        if (!showOnState.includes(lastOrder.state)) {
            return <></>
        }

        const isDone = lastOrder.state === OrderState.DONE;
        return <RestaurantLink to={`/${CUSTOMER_NAV.CONFIRM}/${lastOrder.id}`}>

            <div className={`center p-1 ${isDone ? 'bg-success' : 'bg-warning'}`}>
                {!isDone && <div className="spinner-grow spinner-grow-sm text-primary" role="status" />}
                <div className="ms-2">
                    {isDone ? text.textMenuConfirmDone : text.textMenuConfirmWeArePreparing}
                </div>
                <i className="bi bi-chevron-double-right" />
            </div>
        </RestaurantLink>

    }

    return (
        <div className='pb-3'>
            <div className="menu row m-0">
                <div className="col-2">
                    {!props.noBack &&
                        <RestaurantLink to={props.back || `/`}>
                            {ArrowBackSVG}
                        </RestaurantLink>
                    }
                </div>
                <div className="col-8 center">
                    <BusyIndicator minutesToLatestOrder={minutesToLatestOrder} text='inside' />

                    <RestaurantLink className='ms-2' to={`/`}>
                        <Logo />
                    </RestaurantLink>
                </div>
                <div className="col-2 d-flex align-items-center justify-content-end">
                    <LoyaltyPointsIndicator />
                    <Dropdown className='center dropdown-left'>
                        <Dropdown.Toggle as={publicTerminal ? ThreeLinesToggle : AccountLinesToggle} className="h2 text-primary" />
                        <Dropdown.Menu align="end">
                            {dropdownItems}

                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                <OpeningHoursModal show={showHours} onHide={() => setShowHours(false)} />
            </div>
            {rendetLastOrder()}
        </div>
    )
}

import { useNavigate } from "react-router-dom";
import { useRestaurant } from "../contexts/RestaurantContext";
import { getPersistableQueryParams } from "../hooks/useQueryParams";
import Restaurant from "../models/Restaurant";
import { ProductChoice } from "../models/ProductCounter";
import { Order } from "../models/Order";

interface PathOptions {
    params?: { [key: string]: string },
}

export const getRestaurantPath = (restaurant: Restaurant, path: string, options: PathOptions = {}) => {
    let resultPath = path;

    if (!resultPath.includes('?')) {
        const queryParams = getPersistableQueryParams();

        if (options.params) {
            for (const key in options.params) {
                queryParams.set(key, options.params[key]);
            }
        }

        resultPath = `${resultPath}?${queryParams.toString()}`;
    }

    return resultPath;
}


export const getRestaurantFullUrl = (restaurant: Restaurant, path: string, options: PathOptions = {}) => {
    return window.location.origin + getRestaurantPath(restaurant, path, options);
}

export const getRestaurantOrderCallbackPath = (orderId: string, restaurant: Restaurant) => {
    const path = getRestaurantPath(restaurant, `/confirm/${orderId}`);
    const finalPath = path.startsWith('/') ? path : `/${path}`;
    return `${window.location.origin}${finalPath}`;
}

export const useRestaurantNavigate = () => {
    const restaurant = useRestaurant();
    const navigate = useNavigate();

    return (path: string) => navigate(getRestaurantPath(restaurant, path));
}

interface CounterResult {
    counterId: string,
    counterName: string,
    value: number,
    ruleIx?: number,
}
export const calculateCounters = (restaurant: Restaurant, productChoice: ProductChoice) => {
    const results: CounterResult[] = [];

    const counters = restaurant.productCounters || [];
    for (const counter of counters) {
        const result: CounterResult = {
            counterId: counter.id,
            counterName: counter.name,
            value: 0
        }

        const rules = counter.rules || [];
        for (let ix = 0; ix < rules.length; ix++) {
            const rule = rules[ix];
            if (rule.productId !== productChoice.productId) {
                continue;
            }

            const requredChoices = rule.choiceIds;
            const allRequredFulfilled = requredChoices.every(choiceId => productChoice.choiceIds.includes(choiceId));

            if (allRequredFulfilled) {
                result.value = rule.counter;
                result.ruleIx = ix;
                break;
            }
        }

        results.push(result);
    }
    return results
}

export const calculateCountersForOrders = (restaurant: Restaurant, orders: Order[]) => {
    const resultsPerOrder = orders.map(order => calculateCountersForOrder(restaurant, order));
    return sumMultipleCounters(resultsPerOrder);
}

export const calculateCountersForOrder = (restaurant: Restaurant, order: Order) => {
    const items = order.items || [];
    const resultsPerItem = items.map(item => {
        const baseResults = calculateCounters(restaurant, { productId: item.productId, choiceIds: item.choices });
        for (const baseResult of baseResults) {
            baseResult.value *= item.quantity;
        }
        return baseResults;
    });

    return sumMultipleCounters(resultsPerItem);

}

const sumMultipleCounters = (input: CounterResult[][]) => {
    const result: CounterResult[] = [];

    for (const counters of input) {
        for (const counter of counters) {
            const existing = result.find(r => r.counterId === counter.counterId);
            if (existing) {
                existing.value += counter.value;
            } else {
                result.push({ ...counter });
            }
        }
    }

    return result;
}

import React from 'react'
import Menu from '../../components/customer/Menu'
import NeonText from '../../components/customer/NeonText';
import StampCards from '../../components/customer/StampCards';
import { useText } from '../../contexts/TextContext'
import { StravaConnect } from '../../components/customer/StravaConnect';
import LoyaltyPointsIndicator from '../../components/customer/LoyaltyPointsIndicator';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { FEATURE } from '../../utils/shared/constants';

export default function MyBenefits() {
    const text = useText();
    const restaurant = useRestaurant();


    const loyaltyEnabled = !!restaurant.features?.[FEATURE.LOYALTY_POINTS_GAIN_DIVIDER];
    return (
        <div className='streach'>
            <Menu />
            <div className='container text-align-left text-primary d-flex flex-column gap-3'>

                <div>
                    <h2 className='m-0'>{text.myBenefits}</h2>
                    <p className='m-0 text-muted small'>{text.myBenefitsDescription1}</p>
                </div>

                {loyaltyEnabled && <div>
                    <h3 className='m-0'>{text.loyaltyPoints}</h3>
                    <p className='m-0 text-muted small'>{text.loyaltyPointsDescription}</p>
                    <LoyaltyPointsIndicator />
                </div>}


                <StampCards />

                <StravaConnect />

                <div>
                    <h3 className='m-0'>{text.refferalProgram}</h3>
                    <p className='text-muted small'>{text.refferalProgramDescription}</p>
                    <NeonText text={text.comingSoon} />
                </div>
            </div>
        </div>
    )
}

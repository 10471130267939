import React from 'react'
import { useRestaurant } from '../../contexts/RestaurantContext'
import { useAuth } from '../../contexts/AuthContext';
import { FEATURE } from '../../utils/shared/constants';
import { useText } from '../../contexts/TextContext';
import { Button } from 'react-bootstrap';
import { useCart } from '../../contexts/CartContext';
import { loyaltyPointsEarn, sumCart } from '../../utils/shared/cart';
import AmountCountUp from './AmountCountUp';

export default function LoyaltyPointsInfo() {
    const restaurant = useRestaurant();
    const text = useText();
    const { currentUser, showLoginModal } = useAuth();
    const { cart } = useCart();
    const { sum } = sumCart(cart, {
        avaiblePoints: {
            pointsBalance: currentUser?.user?.loyaltyPoints?.[restaurant.id] || 0,
            pointsValueDivider: restaurant.features?.[FEATURE.LOYALTY_POINTS_VALUE_DIVIDER]
        }
    });

    const isLoyaltyEnabled = restaurant.features?.[FEATURE.LOYALTY_POINTS_GAIN_DIVIDER];

    if (!isLoyaltyEnabled) {
        return <></>;
    }

    if (currentUser) {
        const gainedPoints = loyaltyPointsEarn(sum, restaurant);
        if (!gainedPoints) {
            return <></>;
        }
        return <div className='alert alert-info d-flex flex-column align-items-start gap-2'>
            <div>{text.youWillEarn}: <b><AmountCountUp onlyInteger amount={gainedPoints} /> {text.points}</b></div>
        </div>
    }

    return (
        <div className='alert alert-warning d-flex flex-column align-items-start gap-2'>
            <div>{text.loyaltyPointsWarning}</div>
            <Button variant='clear' className='bold p-0' onClick={showLoginModal} >Login</Button>
        </div>
    )
}

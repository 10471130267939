import React from 'react'
import { Badge } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { FEATURE } from '../../utils/shared/constants';

export default function LoyaltyPointsIndicator() {
    const { currentUser } = useAuth();
    const restaurant = useRestaurant();

    const loyaltyEnabled = !!restaurant.features?.[FEATURE.LOYALTY_POINTS_GAIN_DIVIDER];

    if (!currentUser || !loyaltyEnabled) {
        return <></>
    }

    const points = currentUser?.user?.loyaltyPoints?.[restaurant.id] || 0;

    return (
        <Badge bg='primary' className='text-background'>{points || 0} p</Badge>
    )
}

import { PDFDownloadLink } from '@react-pdf/renderer'
import { collection, doc, getDocs, limit, query, setDoc, where } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { Collections } from '../../utils/shared/constants'
import { useDB } from '../../contexts/FirebaseContext'
import { useRestaurant } from '../../contexts/RestaurantContext'
import { useText } from '../../contexts/TextContext'
import { Order } from '../../models/Order'
import Receipt from '../../models/Receipt'
import { fromFirebaseDocs } from '../../utils/shared/firebase'
import PDFReceipt from '../print/PDFReceipt'
import useQueryParam from '../../hooks/useQueryParams'
import { FormControl, InputGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { validateEmail } from '../../utils/format'
import { useBackend } from '../../hooks/useBackend'
import { AdvancedButton } from '../AdvancedButton'
import { toast } from 'react-toastify'

interface Props {
    order: Order
}

interface SendConfirmationInput {
    email: string;
}

export default function CustomerReceiptForm(props: Props) {
    const restaurant = useRestaurant();
    const [receiptPrinted, setReceiptPrinted] = useState(false);
    const { order } = props;
    const db = useDB();
    const text = useText();
    const { publicTerminal } = useQueryParam();
    const { handleSubmit, formState: { errors }, register } = useForm<SendConfirmationInput>();
    const { sendConfirmationEmail } = useBackend();
    const [processing, setProcessing] = useState(false);
    const [sent, setSent] = useState(false);

    useEffect(() => {
        const q = query(collection(db, `${Collections.restaurants}/${restaurant.id}/${Collections.receipts}`), where('orderId', '==', order.id), limit(1));
        getDocs(q).then((querySnapshot) => {
            const receipts = fromFirebaseDocs<Receipt>(querySnapshot.docs);
            if (receipts.length > 0 && receipts[0].receiptPrintedAt) {
                setReceiptPrinted(true);
            }
        })
    }, [db, restaurant.id, order.id])

    const onReceiptPrint = async () => {
        const orderId = order.id || '';
        const newReceipt: Receipt = {
            orderId,
            receiptPrintedAt: new Date(),
        }
        await setDoc(doc(db, `${Collections.restaurants}/${restaurant.id}/${Collections.receipts}/${orderId}`), newReceipt);
        setReceiptPrinted(true);
    }


    const handleConfirmationSend = async (data: SendConfirmationInput) => {
        setProcessing(true);

        try {
            await sendConfirmationEmail(data.email, restaurant.id, order.id || '');
            toast.success(text.confirmationSent);
            setSent(true);
        } catch (e) {
            console.error(e);
            toast.error(text.errorDefault)
        }

        setProcessing(false);
    }

    if (publicTerminal) {
        return <div className='mt-5 d-flex flex-column align-items-start gap-2 w-100'>
            <p className='bold m-0'>{text.receipt}</p>
            <p className='small text-muted m-0'>{text.enterYourEmailForReceipt}</p>
            {
                sent
                    ? <p>{text.confirmationSent}</p>
                    : <form onSubmit={handleSubmit(handleConfirmationSend)}>
                        <InputGroup >
                            <FormControl placeholder={text.email}

                                type='email'
                                isInvalid={!!errors.email} {...register('email', {
                                    required: true,
                                    validate: validateEmail
                                })} />
                            <AdvancedButton processing={processing} type="submit" variant="outline-secondary" id="button-addon2">
                                {text.send}
                            </AdvancedButton>
                        </InputGroup>
                    </form>
            }
        </div>
    }

    return <PDFDownloadLink document={<PDFReceipt order={order} restaurant={restaurant} copy={false} />}
        fileName={`${restaurant.name}_${order.publicId}.pdf`}>
        {({ loading }) => (loading ? '' : <button className="btn btn-light mt-5"
            disabled={receiptPrinted}
            onClick={onReceiptPrint}>
            <i className="bi bi-receipt me-2" />{receiptPrinted ? text.receiptPrinted : text.receipt}
        </button>)}
    </PDFDownloadLink>
}

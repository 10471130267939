import React, { useEffect, useState } from 'react'
import { Order } from '../models/Order';
import { LOCAL_STORAGE_KEYS } from '../constants/localStorage';
import { doc, onSnapshot } from 'firebase/firestore';
import { useDB } from './FirebaseContext';
import { useRestaurant } from './RestaurantContext';
import { fromFirebaseDoc } from '../utils/shared/firebase';
import { Collections } from '../utils/shared/constants';

interface LastOrderContextData {
    lastOrder: Order | null
    setLastOrderId: (id: string) => void
}

interface Props {
    children: any
}

const lastOrderContext = React.createContext<LastOrderContextData>({
    lastOrder: null,
    setLastOrderId: () => { }
});

export const useLastOrder = () => React.useContext(lastOrderContext);


interface State {
    lastOrderId: string | null
    lastOrder: Order | null
}

export default function LastOrderContext({ children }: Props) {
    const db = useDB();
    const { id: restaurantId } = useRestaurant();
    const [currentState, setCurrentState] = useState<State>({
        lastOrderId: null,
        lastOrder: null
    });

    useEffect(() => {
        const lastOrderId = localStorage.getItem(LOCAL_STORAGE_KEYS.LAST_ORDER_KEY);
        if (lastOrderId) {
            setCurrentState({ ...currentState, lastOrderId });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (currentState.lastOrderId) {
            const ref = doc(db, `${Collections.restaurants}/${restaurantId}/${Collections.orders}/${currentState.lastOrderId}`);

            return onSnapshot(ref, (doc) => {
                if (doc.exists()) {
                    const order = fromFirebaseDoc<Order>(doc);
                    setCurrentState(curr => ({ ...curr, lastOrder: order }));
                }
            });
        }

    }, [currentState.lastOrderId, db, restaurantId])

    const setLastOrderId = (id: string) => {
        setCurrentState({ lastOrder: null, lastOrderId: id });
        localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_ORDER_KEY, id);
    }

    return (
        <lastOrderContext.Provider value={{ ...currentState, setLastOrderId }}>
            {children}
        </lastOrderContext.Provider>
    )
}
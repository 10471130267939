import React from 'react'
import { useRestaurant } from '../../contexts/RestaurantContext';
import { FEATURE } from '../../utils/shared/constants';
import { ConditionalWarning } from '../../models/ConditionalWarning';
import { useCart } from '../../contexts/CartContext';
import { Condition, ConditionField, ConditionOperator } from '../../models/Condition';
import { sumCart } from '../../utils/shared/cart';
import RichEditor from '../RichEditor';

export default function CheckoutWarnings() {
    const restaurant = useRestaurant();
    const { cart } = useCart();
    const cartSum = sumCart(cart).sum
    const warnings: ConditionalWarning[] = restaurant.features?.[FEATURE.CHECKOUT_WARNINGS] || []

    const applicableWarnings = warnings.filter(warning => isConditionMet(cartSum, warning.condition))

    if (applicableWarnings.length === 0) {
        return null;
    }

    return <>
        {applicableWarnings.map((warning, index) => <div key={index} className='alert alert-warning'>
            <RichEditor readOnly={true} raw={warning.text} />
        </div>)}
    </>
}

const isConditionMet = (cartSum: number, condition: Condition) => {
    if (condition.field !== ConditionField.AMOUNT) {
        console.error('Unsupported condition field', condition.field)
        return false;
    }

    let a = cartSum / 100;
    let b = condition.value;
    if (condition.operator === ConditionOperator.EQUAL) {
        return a === b;
    } else if (condition.operator === ConditionOperator.GREATER_THAN) {
        return a > b;
    } else if (condition.operator === ConditionOperator.LESS_THAN) {
        return a < b;
    } else {
        console.error('Unsupported condition operator', condition.operator)
        return false;
    }
}

const text = {


    name: 'Namn',
    officialName: 'Officiellt namn',
    organizationNumber: 'Organisations nummer',
    adddress: 'Adress',
    officialAdddress: 'Officiell adress',
    contactEmail: 'Kontakt email',
    contactPhone: 'Kontakt telefon',
    addToCart: 'Lägg till',
    products: 'Produkter',
    checkout: 'Till kassan',
    backToOrder: 'Tillbaka till menyn',
    sum: 'Summa',
    swish: 'Swish',
    card: 'Kort',
    or: 'Eller',
    notFound: 'Finns ej',
    notAuthorized: 'Du saknar behörighet',
    category: 'Katogori',
    minute_short: 'min',
    confirm: 'Bekräfta',
    processing: 'Bearbetar',
    enable: 'Aktivera',
    disable: 'Inaktivera',
    and: 'och',
    restaurantIsDisabled: 'Restaurangen är stängd för tillfället',
    otherLanguage: 'English',
    confirmation: 'Bekräftelse',
    tip: 'Dricks',
    tips: 'Dricks',
    send: 'Skicka',

    errorDefault: 'OJ! Det blev fel. Prova snart igen',
    errorInvalidProduct: 'Produkten verkar va slut, prova en annan.',
    errorMessaging: 'Det gick inte aktivera notifikation',
    successDefault: 'Snyygt! Det funkade.',

    cartEmptyText: 'Kundvagnen är tyvärr tom!',
    swishOnOtherDevice: 'Swish på annan enhet',
    paymentFailed: 'Betalning missslyckad.',
    tryAgain: 'Prova igen, tack.',

    orderAccepted: 'Vi fick din order, tack!!',
    orderInQueue: 'Antal ordrar innan dig:',
    orderReady: 'Din order är klar!',
    orderNumber: 'Order Nummer',

    followTheStatus: 'Det här sidan uppdateras automatiskt med status om orderstatus .',

    login: 'Logga in',
    logout: 'Logga ut',
    login_email: 'Email',
    login_password: 'Lösenord',
    login_fail: 'Fel lösenord, prova annan mail eller lösenord',
    login_fail_too_many_attempts: 'Försökt för många gånger, prova senare',

    orderReset: 'Systemet kommer nu återställas för nästa kund',
    keepActive: 'Nej vänta!',

    paid: 'Betald',
    todo: 'Att göra',
    inProgress: 'Tilllagas',
    done: 'Färdig',

    dashboard: 'Anslagstavla',

    newProduct: 'Ny produkt',
    save: 'Spara',
    saving: 'Sparar ...',
    saved: 'Sparat',
    add: 'Addera',
    archive: 'Arkivera',
    cancel: 'Avbryt',
    back: 'Tillbaka',
    changeState: 'Ändra status',
    openingHours: 'Öppetider',
    open: 'Öppen',
    closed: 'Stängd',
    close: 'Stäng',
    openDescription: 'Använd öppettider för att bestämma när beställningar ska godkännas',
    closeDescription: 'Do not accept orders and show custom text below',
    customerContact: 'Kunds kontakt information',
    okey: 'Okej',
    delete: 'Ta bort',
    apply: 'Applicera',
    closedMessage: 'Skriv varför ni har stängt',
    deleteProduct: 'Ta bort produkt',
    deleteProductConfirm: 'Är du säker på att du vill ta bort denna produkt?',
    thisActionCannotBeUndone: 'Tyvärr går det inte att utföra.',
    color: 'Färg',
    beta: 'Testversion',

    // Confirm
    noEstimate: 'Restaurangen ger dig snart en estimerad tid!',
    estimateTime: 'Din order kommer va klar om ungefär',
    minute: 'minut',
    minutes: 'minuter',
    orderQueueWaitTime: 'Beställningskö tid',

    /// Receipt
    receipt: 'Kvitto',
    returnReceipt: 'Returnkvitto',
    vat: 'Moms',
    totalVat: 'Total moms',
    paidViaSwish: 'Betald med Swish',
    paidViaCard: 'Betald med Kort',
    nameOfRegister: 'Kassanamn',
    electronicReceipt: 'elektroniskt kassakvitto',
    receiptCopy: 'kopia',
    receiptReturn5Days: 'Pengarna kommer på ditt konto innom 5 arbetsdagar',
    enterYourEmailForReceipt: "Ange din e-postadress för kvittot",
    confirmationSent: "Bekräftelse skickad",

    /// Orders - Checkout
    orders: 'Beställningar',
    history: 'Historik',
    analytics: 'Statestik',
    ordersTotal: 'Total antal beställningar',
    salesTotal: 'Total såld i sek',
    setup: 'Inställningar',
    payByCard: "Betala med kort",
    byProceeding: 'Genom att betala med kort, godkänner du våra',
    termsConditions: 'Vilkor',
    privacyPolicy: 'integritetspolicy',
    productCost: 'Produktkostnad',
    changeCount: 'Antal ändringar',
    pickupTimeIsNotAvailable: 'Plottiden är inte längre tillgänglig, välj en annan tid',
    iPaid: 'Jag har betalat',
    weFailedToVerify: 'Vi kunde inte verifiera din betalning, kontakta personalen',

    /// Options
    options: 'Alternativ',
    addOption: 'Addera alternativ',
    addChoice: 'Addera val',
    mandatory: 'Obligatoriskt',
    choiceName: 'Val namn',
    optionName: 'Alternativ namn',
    choicePriceAdjustment: 'Pris',

    /// Staff
    restaurant: 'Restaurang',
    salesDate: 'Datum såld',
    from: 'Från',
    to: 'Till',
    clearFilters: 'Rensa alla filter',
    generateSIFile: 'Generera SI File',
    siFilterRequired: 'För att generera SI File, väl mellan vilka datum',
    estimatedTime: 'Estimerad tid',
    default: 'Föreställt värde',
    makeDefault: 'Använd föreställt värde',
    makeNotDefault: 'Använd inte föreställt värde',
    generatePdfReport: 'Generera pdf raport',
    orderReport: 'Beställnings rapport',
    generateReportInstruction: 'Generera SI fil för att få pdf raport',

    // Features
    features: 'features',
    estimateType: 'Vilket kö system',
    queue: 'Kö',
    queueDescription: 'Kunden får sitt nummer I ordningen av beställningar.',
    setByStaff: 'sätts av personalen',
    setByStaffDescription: 'Kunden frågas vänta för att en personal ska estimera deras vänta tid.',
    paymentMethods: 'Betalnings metod',
    enabled: 'Sätt på',

    checkoutText: 'Utchecknings Text',
    none: 'inget',
    text: 'Text',
    textDescription: 'Text som syns ovanför betalningsknappen.',
    textConfirm: 'Bekräfta',
    textConfirmDescription: 'Texten behöver accepteras innan ordern går igenom.',
    upselHeader: 'Vill du lägga till något mer?',
    placeOrder: 'Lägg order',

    // Branding
    branding: 'Branding',
    logo: 'Logotyp',
    updateLogo: 'Update Logotyp',
    primary: 'Primär färg',
    bgPrimary: 'Primär bakgrundsfärg',
    bgSecondary: 'Andra bakrundsfärg',

    // Accounting
    accounting: 'Bokföring',
    financialYearStart: 'Räkenskapsåret startar:',
    accounts: 'Konton',
    bankAccount: 'Bank konto',
    salesAccount: 'Sälj konton',

    // Deals
    deals: 'Kampanjer',
    newDeal: 'Nya Kampanjer',
    addDeal: 'Addera Kampanjer',
    code: 'Kod',
    discount: 'Rabatt',
    discountCode: 'Rabatt kod',
    codeApplied: 'Koden lyckades',
    codeNotFound: 'Fel kod',


    // My Benefits
    myBenefits: 'Mina förmåner',
    myBenefitsDescription1: 'Grattis! Du är en del av att göra världen grönare. Ta del av dessa förmåner.',
    refferalProgram: 'Refferal Program',
    refferalProgramDescription: 'Refferera en vän och få en gratis måltid',
    stampCards: 'Stämpelkort',
    stampCardsDescription: 'Samla stämplar och få en gratis måltid',
    activity: 'Aktivitet',
    activityDescription: 'Vi tycker att det är bra att äta hälsosamt. Men vi tycker att det är ännu bättre att röra på sig och äta hälsosamt. Så vi vill belöna dig för varje steg du tar.',
    connectStrava: 'Anslut Strava',
    loyaltyPoints: 'Lojalitetspoäng',
    loyaltyPointsDescription: 'Tjäna poäng för varje köp och spendera dem på våra godsaker',
    loyaltyPointsWarning: 'Visste du att inloggade användare tjänar poäng för varje köp och kan spendera dem på våra godsaker?',
    loyalty100to100: '100 spenderat = 100 poäng',
    loyalty100to10: '100 spenderat = 10 poäng',
    youWillEarn: 'Du kommer tjäna',


    // Pickup
    pickup: 'Plocka upp',
    asap: 'Så snart som möjligt',
    today: 'Idag',
    tomorrow: 'Imorgon',
    chooseDate: 'Väl datum',
    chooseTime: 'Väl tid',

    // Day Reports
    xReport: 'X-dagrapport',
    zReport: 'Z-dagrapport',
    payments: 'Betalningar',
    sales: 'Sälj',
    returns: 'Returer',
    totalSales: 'Total sälj',
    totalNetto: 'Total netto',
    printDate: 'Skriv datum',
    includingVATSh: 'Inkl. moms',
    excludingVATSh: 'Exkl. moms',
    receipts: 'Kvitton',
    receiptCopies: 'Kopia av Kvitton',
    openingCashBalance: 'Ingående växelkassa',
    cashWithdrawals: 'Kontantuttag från kassan',
    grandTotalSalse: 'Summa försäljning',
    grandTotalReturns: 'Summa returer',
    grandTotalNetto: 'Summa nettoförsäljning'
};

export default text;
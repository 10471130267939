import React, { useState } from 'react'
import { useText } from '../../contexts/TextContext';
import { Cart, CartItem } from '../../models/Cart'
import { Order } from '../../models/Order'
import { sumCart } from '../../utils/shared/cart';
import { printAmount } from '../../utils/shared/converters';
import { useCart } from '../../contexts/CartContext';
import { useProducts } from '../../contexts/ProductsContext';
import { ProductOptionInputMandatory, ProductOptionInputOptional } from './ProductOptionInput';
import AmountCountUp from './AmountCountUp';
import { useAuth } from '../../contexts/AuthContext';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { FEATURE } from '../../utils/shared/constants';
import { DealType } from '../../models/Deal';

interface Props {
    order: Cart | Order,
    readOnly?: boolean,
}

export default function LineItems(props: Props) {
    const { order } = props;
    const { currentUser } = useAuth();
    const text = useText();
    const restaurant = useRestaurant();
    const pointsBalance = currentUser?.user?.loyaltyPoints?.[restaurant.id] || 0;
    const { sum, discount, tip, appliedDeals } = sumCart(order, {
        avaiblePoints: {
            pointsBalance,
            pointsValueDivider: restaurant.features?.[FEATURE.LOYALTY_POINTS_VALUE_DIVIDER]
        }
    });
    const [openedLine, setOpenedLine] = useState(-1);
    const { updateItem } = useCart();
    const { allProductsById } = useProducts();


    const handleLineClick = (ix: number) => {
        if (props.readOnly) {
            return;
        }
        setOpenedLine(openedLine === ix ? -1 : ix);
    }



    let discountLabel = text.discount;
    const loyaltyPointsDeal = appliedDeals.find(deal => deal.type === DealType.LOYALTY_POINTS);
    if (loyaltyPointsDeal) {
        discountLabel = `${text.discount} (${loyaltyPointsDeal.discount} ${text.points})`
    }
    return (<div className='d-flex flex-column gap'>
        <div className='d-flex flex-column gap-4 mb-3'>

            {order.items.map((item, ix) => {
                const lineTotal = item.itemPrice * item.quantity;
                const canBeLowered = item.quantity > 1;
                const isLineSelected = openedLine === ix
                const isSomethingElseSelected = !isLineSelected && openedLine >= 0;
                const product = allProductsById[item.productId];
                const productOptions = product?.options || [];
                const choiceCount = productOptions.reduce((acc, option) => acc + option.choices.length, 0);
                const openSize = (choiceCount + 1) * 70;

                const changeQuantity = (e: React.MouseEvent, item: CartItem, newQuantity: number) => {
                    e.stopPropagation();

                    const newItem: CartItem = {
                        ...item,
                        quantity: newQuantity
                    }
                    updateItem(newItem, product);
                }

                const handleChoiceMandatory = (optionIx: number, choiceId: string) => {
                    const optionChoices = product.options[optionIx].choices.map(choice => choice.id);
                    const newChoices = item.choices
                        .filter(choosenId => !optionChoices.includes(choosenId))
                        .concat([choiceId]);

                    updateItem({ ...item, choices: newChoices }, product);
                }

                const handleChoiceOptional = (choiceId: string, checked: boolean) => {
                    const newChoices = checked ? item.choices.concat([choiceId]) : item.choices.filter(choice => choice !== choiceId)
                    updateItem({ ...item, choices: newChoices }, product);
                }


                return <div key={ix} className="animate" style={{
                    opacity: isSomethingElseSelected ? 0.5 : 1
                }}>
                    <div className='row pointer align-items-start' onClick={() => handleLineClick(ix)}>

                        <div className='col-7  d-flex flex-column align-items-start gap-1'>
                            <div>{item.itemName} <i className='bi bi-pencil-square ' /></div>
                            <div className='text-small text-muted'>{text.productCost}: {printAmount(item.itemPrice)}</div>

                            <div className='border rounded-pill center px-1' style={{ gap: '0.1rem' }}>
                                <button className={`btn btn-sm text-small text-primary mt-1`}
                                    onClick={e => changeQuantity(e, item, item.quantity - 1)}>
                                    <i className={`bi ${canBeLowered ? 'bi-dash' : 'bi-trash'}`} />
                                </button>
                                <span className='bold text-small'>{item.quantity}</span>
                                <button className='btn btn-sm text-small text-primary mt-1'
                                    onClick={e => changeQuantity(e, item, item.quantity + 1)}>
                                    <i className='bi bi-plus ' />
                                </button>
                            </div>
                        </div>


                        <div className='col-2 center' >
                        </div>

                        <div className='col-3 text-end '>
                            <div className=''><AmountCountUp amount={lineTotal} /></div>
                        </div>
                    </div>
                    <div className='animate-open px-2' style={{ maxHeight: isLineSelected ? openSize : undefined }}>
                        {productOptions.length > 0 && <div className='pb-2'>

                            {productOptions.map((option, optionIx) => option.mandatory
                                ? <ProductOptionInputMandatory size='sm' key={optionIx} option={option} choosen={item.choices} handleChoice={(choiceIx) => handleChoiceMandatory(optionIx, choiceIx)} />
                                : <ProductOptionInputOptional size='sm' key={optionIx} option={option} choosen={item.choices} handleChoice={handleChoiceOptional} />
                            )}

                        </div>}


                    </div>
                </div>
            })}
        </div>
        {!!discount && <div className="d-flex justify-content-between ">
            <div >{discountLabel}</div>
            <div ><AmountCountUp amount={-discount} /></div>
        </div>}
        {!!order?.tip && <div className="d-flex justify-content-between ">
            <div >{`${text.leaveTip} (${order.tip} %)`}</div>
            <div ><AmountCountUp amount={tip} /></div>
        </div>}
        <div className="d-flex justify-content-between fs-5">
            <div >{text.sum}</div>
            <div className='bold' ><AmountCountUp amount={sum} /></div>
        </div>
    </div>
    )
}

import React, { useContext } from "react"
import { Language } from "../models/Settings";
import text from '../utils/texts/base';
import textSv from '../utils/texts/sv';
import { useSettings } from "./SettingsContext";


const textContext = React.createContext(text);

export function useText(forceLanguage?: Language) {
    const textFromContext = useContext(textContext);
    if (forceLanguage) {
        return getTextVariant(forceLanguage);
    }

    return textFromContext
}

export const textApplyVariables = (sourceText: string, variables: Record<string, string>) => {
    let finalText = sourceText;

    for (const variable of Object.keys(variables)) {
        finalText = finalText.replace(`{${variable}}`, variables[variable]);
    }

    return finalText;
}


const getTextVariant = (language: string) => {
    let finalText = text;
    if (language === 'swedish') {
        finalText = {
            ...finalText,
            ...textSv,
        }
    }
    return finalText;
}


export default function TextProvider({ children }: any) {
    const { settings } = useSettings();

    const finalText = getTextVariant(settings.language)

    return (
        <textContext.Provider value={finalText}>
            {children}
        </textContext.Provider>
    )
}
import { QUERY_PARAMS } from "../utils/shared/constants";

export const parseQueryParam = () => {
    const search = window.location.search;
    const urlParams = new URLSearchParams(search);
    const publicTerminal = urlParams.get('publicTerminal');
    return {
        publicTerminal: (publicTerminal || '') === 'true' ? 'true' : '',
        [QUERY_PARAMS.EMAIL_SIGNUP_FINISH]: !!urlParams.get(QUERY_PARAMS.EMAIL_SIGNUP_FINISH),
        [QUERY_PARAMS.RESTAURANT]: urlParams.get(QUERY_PARAMS.RESTAURANT) || '',
        [QUERY_PARAMS.DEBUG]: !!urlParams.get(QUERY_PARAMS.DEBUG),
        [QUERY_PARAMS.DEAL_ID]: urlParams.get(QUERY_PARAMS.DEAL_ID) || '',
    }
}

export const addQueryParam = (key: string, value: string) => {
    const search = window.location.search;
    const urlParams = new URLSearchParams(search);
    urlParams.set(key, value);
    return urlParams.toString();
}

export const getPersistableQueryParams = () => {
    const { publicTerminal, restaurant } = parseQueryParam();

    const data: Record<string, string> = {};

    if (publicTerminal === 'true') {
        data.publicTerminal = 'true';
    }
    if (restaurant) {
        data[QUERY_PARAMS.RESTAURANT] = restaurant;
    }

    return new URLSearchParams(data);
}

const useQueryParam = () => {
    return parseQueryParam();
}


export default useQueryParam;
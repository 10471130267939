import { useEffect, useState } from 'react'
import { CHECKOUT_CUSTOM_TEXT, FEATURE } from '../../utils/shared/constants';
import { CUSTOMER_NAV } from '../../constants/routes';
import { useCart } from '../../contexts/CartContext';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { useText } from '../../contexts/TextContext';
import { useBackend } from '../../hooks/useBackend';
import RestaurantLink from '../RestaurantLink';
import RichEditor from '../RichEditor';
import cardPhysicalLogo from '../../assets/cardPhysical.jpg';
import useOpenState from '../../hooks/useOpenState';
import NeonText from './NeonText';
import { usePublicTerminal } from '../../contexts/PublicTerminalContext';
import { PAYMENT_TYPES } from '../../utils/shared/constants';
import CheckoutPaymentSwish from './CheckoutPaymentSwish';
import useQueryParam from '../../hooks/useQueryParams';
import { getRestaurantPath } from '../../utils/restaurant';
import { useConfirm } from '../../contexts/ConfirmContext';
import { toast } from 'react-toastify';
import CheckoutPaymentStripeOnline from './CheckoutPaymentStripeOnline';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { sumCart } from '../../utils/shared/cart';
import { AdvancedButton } from '../AdvancedButton';
import { useAuth } from '../../contexts/AuthContext';


interface PaymentDetails {
    text: string
    logo: JSX.Element
}

const existingPayments: Record<string, PaymentDetails> = {
    swish: {
        text: 'Swish',
        logo: <img className='paymentLogoSmall' src={'/assets/swish-logo.svg'} alt="swish" />
    },
    worldline: {
        text: 'Card',
        logo: <img className='paymentLogoSmall' src='/assets/card.svg' alt="card" />
    },
    card: {
        text: 'Card (physical)',
        logo: <img className='paymentLogoSmall' src={cardPhysicalLogo} alt="card" />
    },
    stripeTerminal: {
        text: 'Card',
        logo: <img className='paymentLogoSmall' src={cardPhysicalLogo} alt="card" />
    },
    [PAYMENT_TYPES.STRIPE_ONLINE]: {
        text: 'Stripe',
        logo: <img className='paymentLogoSmall' src={cardPhysicalLogo} alt="card" />
    }
}

interface Props {
    minutesToLatestOrder: number
}

const QUEUE_TIME_WARNING = 20;

export default function CheckoutPayments(props: Props) {
    const text = useText();
    const restaurant = useRestaurant();
    const { currentUser } = useAuth();
    const { isOpen } = useOpenState();
    const [enabledPayments, setEnabledPayments] = useState([PAYMENT_TYPES.SWISH]);
    const [choosenPayment, setChoosenPayment] = useState('');
    const [customText, setCustomText] = useState({ required: false, confirmed: false, show: false })
    const [processing, setProcessing] = useState(false);
    const { initPayment, handleInitPaymentError } = useBackend();
    const { cart } = useCart();
    const { isPublicTerminal } = usePublicTerminal();
    const params = useQueryParam();
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
    const openConfirmModal = useConfirm();
    const confirmText = `Are you sure you want to place your order with queue time more than ${QUEUE_TIME_WARNING} minutes?`;
    const skipConfirm = props.minutesToLatestOrder < QUEUE_TIME_WARNING || !!cart.pickupDate;
    const pointsBalance = currentUser?.user?.loyaltyPoints?.[restaurant.id] || 0;
    const { sum } = sumCart(cart, {
        avaiblePoints: {
            pointsBalance,
            pointsValueDivider: restaurant.features?.[FEATURE.LOYALTY_POINTS_VALUE_DIVIDER]
        }
    });

    useEffect(() => {
        if (!restaurant?.stripePublishableKey) {
            return;
        }

        setStripePromise(loadStripe(restaurant.stripePublishableKey));
    }, [restaurant?.stripePublishableKey])

    useEffect(() => {
        if (restaurant.features?.[FEATURE.ENABLED_PAYMENTS]) {
            let enabledPayments = restaurant.features?.[FEATURE.ENABLED_PAYMENTS] as PAYMENT_TYPES[];

            if (!params.debug) {
                if (isPublicTerminal) {
                    const publicTerminalSupportedPayments = [PAYMENT_TYPES.SWISH, PAYMENT_TYPES.STRIPE_TERMINAL]
                    enabledPayments = enabledPayments.filter((paymentType) => publicTerminalSupportedPayments.includes(paymentType));
                } else {
                    const publicOnly = [PAYMENT_TYPES.STRIPE_TERMINAL];
                    enabledPayments = enabledPayments.filter((paymentType) => !publicOnly.includes(paymentType));
                }
            }

            setEnabledPayments(enabledPayments)
            setChoosenPayment(enabledPayments[0])
        } else {
            setChoosenPayment(PAYMENT_TYPES.SWISH)
        }

        if (restaurant.features?.[FEATURE.CHECKOUT_CUSTOM_TEXT] === CHECKOUT_CUSTOM_TEXT.TEXT_WITH_CONFIRM || restaurant.features?.[FEATURE.CHECKOUT_CUSTOM_TEXT] === CHECKOUT_CUSTOM_TEXT.TEXT_ONLY) {
            const required = restaurant.features?.[FEATURE.CHECKOUT_CUSTOM_TEXT] === CHECKOUT_CUSTOM_TEXT.TEXT_WITH_CONFIRM;
            setCustomText({ confirmed: false, required, show: true });
        } else {
            setCustomText({ confirmed: false, required: false, show: false });
        }

    }, [isPublicTerminal, restaurant, params.debug])

    const confirmCustomText = () => {
        setCustomText({ ...customText, confirmed: true });
    }

    const preCheckCart = () => {
        if (cart.pickupDate && new Date(cart.pickupDate) < new Date()) {
            toast.warn(text.pickupTimeIsNotAvailable);
            return false;
        }

        return true;
    }

    const handleCard = async (type: PAYMENT_TYPES) => {
        const preCheckResult = preCheckCart();
        if (!preCheckResult) {
            return;
        }

        openConfirmModal({
            text: confirmText,
            hideThisActionCannotBeUndone: true,
            skip: skipConfirm,
            callback: async () => {
                setProcessing(true);
                try {
                    const result = await initPayment(cart, restaurant.sysid, type);
                    let redirectUrl = result.redirectUrl;
                    if (type === PAYMENT_TYPES.FREE) {
                        redirectUrl = getRestaurantPath(restaurant, `/${CUSTOMER_NAV.CONFIRM}/${result.orderId}`)
                    } else if (!redirectUrl) {
                        redirectUrl = getRestaurantPath(restaurant, `/${CUSTOMER_NAV.CARD}/${result.orderId}`)
                    }

                    window.location.href = redirectUrl;
                } catch (e) {
                    handleInitPaymentError(e)
                }
                setProcessing(false);
            }
        })
    }

    const renderPaymentMethodContent = (type: string, active: boolean): JSX.Element => {
        switch (type) {
            case PAYMENT_TYPES.SWISH:
                return <div className={`center flex-column paymentTypeContent ${active && 'active'}`}>
                    <CheckoutPaymentSwish confirmText={confirmText} skipConfirm={skipConfirm} preCheckCart={preCheckCart} />
                </div>
            case PAYMENT_TYPES.STRIPE_ONLINE:
                if (!stripePromise) {
                    return <></>
                }

                return <div className={`center flex-column paymentTypeContent ${active && 'active-full'}`}>

                    <Elements stripe={stripePromise} options={{
                        mode: 'payment',
                        amount: sum,
                        currency: 'sek',
                    }}>
                        <CheckoutPaymentStripeOnline confirmText={confirmText} skipConfirm={skipConfirm} preCheckCart={preCheckCart} />
                    </Elements>
                </div>
            case PAYMENT_TYPES.WORLDLINE:
            case PAYMENT_TYPES.STRIPE_TERMINAL:
                return <div className={`paymentTypeContent ${active && 'active'} center`}>
                    <button className="btn btn-primary border" onClick={() => handleCard(type)} disabled={processing}>
                        <span className='me-2'>
                            {processing
                                ? <span className="spinner-grow spinner-grow-sm" />
                                : <i className='bi bi-credit-card' />}
                        </span>
                        {text.payByCard}
                    </button>
                </div>
            default:
                return <></>
        }
    }
    const renderPaymentMethod = (type: string): JSX.Element => {
        const paymentTypeDetails = existingPayments[type];
        if (!paymentTypeDetails) {
            return <></>
        }

        const disableAll = (customText.required && !customText.confirmed);
        const active = !disableAll && choosenPayment === type;
        const disabled = disableAll || (processing && !active)
        const onlyOnePayment = enabledPayments.length === 1;

        if (onlyOnePayment) {
            return <div key={type}>
                {renderPaymentMethodContent(type, true)}
            </div>
        }


        return <div key={type}>
            <div className={`d-flex flex-wrap mt-4 p-1 ${disabled || processing ? '' : 'pointer'}`} onClick={() => setChoosenPayment(type)}>
                <input type="radio" className='form-check-input' name="paymentType" id={type} checked={active}
                    disabled={disabled}
                    onChange={() => setChoosenPayment(type)}
                />
                <div className='ms-3'>{paymentTypeDetails.text}</div>
                {paymentTypeDetails.logo}
            </div>
            {renderPaymentMethodContent(type, active)}
        </div>
    }


    if (restaurant.closed && !params.debug) {
        return <RichEditor readOnly={true} raw={restaurant.closedMessage} />
    }

    if (!isOpen && !cart.pickupDate && !params.debug) {
        return <>
            <NeonText text={text.closed + ' (◔_◔)'} className="mt-5" />
            <p className='center mt-5'>{text.preorderNow}</p>
        </>
    }

    return <div >
        {customText.show && <>
            <RichEditor readOnly={true} raw={restaurant.features?.[FEATURE.CHECKOUT_CUSTOM_TEXT_VALUE]} />
            {customText.required && !customText.confirmed && <button className='btn btn-primary' onClick={confirmCustomText}>{text.okey}</button>}
        </>}


        <div className='d-flex flex-wrap center  mt-2'>
            <small>{text.byProceeding}</small>
            <small className='bold ms-1'><RestaurantLink to={`/${CUSTOMER_NAV.TERMS}`}>{text.termsConditions}</RestaurantLink></small>
            <small className='ms-1'>{text.and}</small>
            <small className='bold ms-1'><RestaurantLink to={`/${CUSTOMER_NAV.PRIVACY}`}>{text.privacyPolicy}</RestaurantLink></small>
        </div>

        {sum === 0
            ? <div className='center mt-3'>
                <AdvancedButton processing={processing} onClick={() => handleCard(PAYMENT_TYPES.FREE)} size='lg' icon='bi-bag'>{text.placeOrder}</AdvancedButton>
            </div>
            : <div className="d-flex flex-column border rounded mt-3 p-2">
                {enabledPayments.map(renderPaymentMethod)}
            </div>
        }
    </div>
}

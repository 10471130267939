import { collection, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react'
import { Collections } from '../../utils/shared/constants';
import { useDB } from '../../contexts/FirebaseContext'
import { useRestaurant } from '../../contexts/RestaurantContext';
import { Order, OrderState } from '../../models/Order';
import { fromFirebaseDocs } from '../../utils/shared/firebase';
import { ESTIMATE_TYPE, FEATURE } from '../../utils/shared/constants';
import { useProducts } from '../../contexts/ProductsContext';
import { calculateOrderRemainingTimeEstimateInMinutes } from '../../utils/shared/cart';

const SIZE = '50px';
const SCHEDULED_ORDER_BUFFER = 0;

const dateToMinsFromEpoch = (date: Date) => date.getTime() / 1000 / 60;



export const useQueueData = () => {
    const [busyMinsFromOrders, setBusyMinsFromOrders] = useState<number>(0);
    const [nowMinsFromEpoch, setNowMinsFromEpoch] = useState<number>(0);
    const { allProductsById } = useProducts();
    const [ordersInQueue, setOrdersInQueue] = useState<Order[]>([]);
    const db = useDB();
    const restaurant = useRestaurant();
    const estimatesType = useMemo(() => restaurant.features?.[FEATURE.ESTIMATE_TYPE] || ESTIMATE_TYPE.QUEUE, [restaurant.features]);

    useEffect(() => {
        const colRef = collection(db, Collections.restaurants, restaurant.id, Collections.orders);

        const q = estimatesType === ESTIMATE_TYPE.QUEUE
            ? query(colRef,
                where('state', 'in', [OrderState.PAID, OrderState.IN_PROGRESS]))
            : query(colRef,
                orderBy('estimate', 'desc'),
                where('state', 'in', [OrderState.PAID, OrderState.IN_PROGRESS]),
                limit(1));

        return onSnapshot(q, snapshot => {
            const orders = fromFirebaseDocs<Order>(snapshot.docs);
            setOrdersInQueue(orders);

            if (estimatesType === ESTIMATE_TYPE.QUEUE) {
                const scheduledOrders = orders.filter(order => order.pickupDate);
                const asSoonAsPossibleOrders = orders.filter(order => !order.pickupDate);


                const remainingTimes = asSoonAsPossibleOrders.map(order => calculateOrderRemainingTimeEstimateInMinutes(order, restaurant, allProductsById));
                let totalRemainingTime = remainingTimes.reduce((sum, time) => sum + time, 0);

                scheduledOrders.sort((a, b) => a.pickupDate!.getTime() - b.pickupDate!.getTime());
                for (const order of scheduledOrders) {
                    const orderTime = calculateOrderRemainingTimeEstimateInMinutes(order, restaurant, allProductsById);
                    const startPrepTime = new Date(order.pickupDate!);
                    startPrepTime.setMinutes(startPrepTime.getMinutes() - orderTime - SCHEDULED_ORDER_BUFFER);


                    const endBusyUntilTime = new Date();
                    endBusyUntilTime.setMinutes(startPrepTime.getMinutes() + orderTime);

                    if (startPrepTime < endBusyUntilTime) {
                        totalRemainingTime += orderTime;
                    } else {
                        break;
                    }
                }

                setBusyMinsFromOrders(totalRemainingTime);
                return;
            }

            if (snapshot.empty) {
                return;
            }

            const order = orders[0];
            if (!order.estimate) {
                return;
            }
            const minutes = dateToMinsFromEpoch(order.estimate)
            setBusyMinsFromOrders(minutes);
        });

    }, [db, restaurant, allProductsById, estimatesType])

    useEffect(() => {
        const updateNowMins = () => {
            const newNowMinutes = dateToMinsFromEpoch(new Date())
            setNowMinsFromEpoch(newNowMinutes);
        }
        const interval = setInterval(updateNowMins, 1000 * 30);
        updateNowMins();
        return () => clearInterval(interval);
    }, [])

    const minutesToLatestOrder = useMemo(() => {
        let busyUntilMins = 0;
        if (restaurant.busyUntil) {
            busyUntilMins = dateToMinsFromEpoch(restaurant.busyUntil) - nowMinsFromEpoch;
        }
        return Math.max(busyUntilMins, busyMinsFromOrders);
    }, [busyMinsFromOrders, nowMinsFromEpoch, restaurant.busyUntil])

    return {
        ordersInQueue,
        minutesToLatestOrder,
    }
}

interface Props {
    minutesToLatestOrder: number
    text: 'after' | 'inside'
}


export default function BusyIndicator(props: Props) {
    const minutesToLatestOrder = props.minutesToLatestOrder;

    let title = '0 - 10';
    let classNames = 'rounded-circle center flex-column text-primary';

    if (minutesToLatestOrder < 10) {
        classNames += ' bg-success';
    } else if (minutesToLatestOrder < 20) {
        classNames += ' bg-warning';
        title = '10 - 20';
    } else {
        classNames += ' bg-danger';
        title = '20+';
    }

    return (
        <div className='center'>
            <div className={classNames} style={{ width: SIZE, height: SIZE }} title={title}>
                {props.text === 'inside' && <>
                    <span className='text-x-small'>est.</span>
                    <span className="bold text-small">{title}</span>
                    <span className='text-x-small'>min</span>
                </>}
            </div>
            {props.text === 'after' && <span className="ms-2 bold">{title} minutes</span>}
        </div>

    )
}

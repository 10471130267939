import { useState } from 'react'
import { useCart } from '../../contexts/CartContext';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { useText } from '../../contexts/TextContext';
import { useBackend } from '../../hooks/useBackend';
import { PAYMENT_TYPES } from '../../utils/shared/constants';
import { getRestaurantOrderCallbackPath } from '../../utils/restaurant';
import { AdvancedButton } from '../AdvancedButton';
import { useConfirm } from '../../contexts/ConfirmContext';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';

interface Props {
    confirmText: string
    skipConfirm: boolean
    preCheckCart: () => boolean
}

export default function CheckoutPaymentStripeOnline(props: Props) {
    const text = useText();
    const restaurant = useRestaurant();
    const [processing, setProcessing] = useState(false);
    const { initPayment, handleInitPaymentError } = useBackend();
    const { cart } = useCart();
    const openConfirmModal = useConfirm();
    const stripe = useStripe();
    const elements = useElements();


    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (!props.preCheckCart()) {
            return;
        }
        if (!elements || !stripe) {
            return;
        }

        openConfirmModal({
            text: props.confirmText,
            hideThisActionCannotBeUndone: true,
            skip: props.skipConfirm,
            callback: async () => {
                setProcessing(true);
                try {
                    const { error: submitError } = await elements.submit();
                    if (submitError) {
                        setProcessing(false);
                        return;
                    }


                    const result = await initPayment(cart, restaurant.sysid, PAYMENT_TYPES.STRIPE_ONLINE);

                    const clientSecret = result.paymentExtras;

                    if (!clientSecret) {
                        throw Error('No client secret');
                    }

                    const { error } = await stripe.confirmPayment({
                        elements,
                        clientSecret,
                        confirmParams: {
                            return_url: getRestaurantOrderCallbackPath(result.orderId, restaurant),
                        },
                    });

                    if (error) {
                        console.error('Stripe error', error);
                    }
                } catch (e) {
                    handleInitPaymentError(e)
                }
                setProcessing(false);
            },
        });
    }


    return <form className='w-100' onSubmit={handleSubmit}>
        <PaymentElement />
        <AdvancedButton icon='bi-wallet' className='mt-3 w-100' onClick={handleSubmit} processing={processing} disabled={!stripe}>
            {text.pay}
        </AdvancedButton>
    </form>

}

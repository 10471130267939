
export const HIDE_FUTURE_ORDERS_MINUTES = 60;

export enum PAYMENT_TYPES {
    SWISH = 'swish',
    WORLDLINE = 'worldline',
    STRIPE_TERMINAL = 'stripeTerminal',
    STRIPE_ONLINE = 'stripeOnline',
    FREE = 'free',
}

export enum QUERY_PARAMS {
    EMAIL_SIGNUP_FINISH = 'finishEmailSignup',
    RESTAURANT = 'restaurant',
    DEBUG = 'debug',
    DEAL_ID = 'dealId',
}

export const STRAVA_CLIENT_ID = '136729';
export const STRAVA_CLIENT_SECRET = '078cefc5188c3038952f989bb09577ba885b8432';

export const DEFAULT_SERVE_TIME = 0;

export enum FEATURE {
    ESTIMATE_TYPE = 'estimateType',
    CHECKOUT_CUSTOM_TEXT = 'checkoutText',
    CHECKOUT_CUSTOM_TEXT_VALUE = 'checkoutTextValue',
    CHECKOUT_WARNINGS = 'warnings',
    ENABLED_PAYMENTS = 'enabledPayments',
    ENABLED_CONTROL_UNIT = 'enabledControlUnit',
    LOYALTY_POINTS_GAIN_DIVIDER = 'loyaltyPoints',
    LOYALTY_POINTS_VALUE_DIVIDER = 'loyaltyPointsValue',
}

export const DEFAULT_LOYALTY_POINTS_GAIN_DIVIDER = 1;
export const DEFAULT_LOYALTY_POINTS_VALUE_DIVIDER = 10;

export enum CHECKOUT_CUSTOM_TEXT {
    NONE = 'none',
    TEXT_ONLY = 'textOnly',
    TEXT_WITH_CONFIRM = 'textConfirm',
}

export enum ESTIMATE_TYPE {
    QUEUE = 'queue',
    TIME = 'time',
}

export enum Collections {
    restaurants = 'restaurants',
    orders = 'orders',
    products = 'products',
    deals = 'deals',
    analytics = 'analytics',
    feedback = 'feedback',
    receipts = 'receipts',
    dayreports = 'dayreports',
    userdeals = 'userdeals',
    auditlog = 'auditlog',
    users = 'users',
    cardPaymentQueue = 'cardPaymentQueue',
    config = 'config',
}


import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import { useText } from '../../contexts/TextContext'
import { dateTimeToUnixSting, dateToUnixSting } from '../../utils/shared/converters';
import { AuditLogItem } from '../../models/AuditLogItem';
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { useDB } from '../../contexts/FirebaseContext';
import { Collections } from '../../utils/shared/constants';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { fromFirebaseDocs } from '../../utils/shared/firebase';
import SkatteverketXMLExport from '../../components/staff/SkatteverketXMLExport';

interface Filters {
    from: Date,
    to: Date,
}

export default function StaffAuditPage() {
    const text = useText();
    const initDateFrom = new Date();
    initDateFrom.setDate(initDateFrom.getDate() - 1);
    const initDateTo = new Date();
    const [filters, setFilters] = useState<Filters>({ from: initDateFrom, to: initDateTo });
    const [auditLog, setAuditLog] = useState<AuditLogItem[]>([]);
    const db = useDB();
    const restaurant = useRestaurant();
    const [openData, setOpenData] = useState<Record<string, boolean>>({})

    useEffect(() => {
        const q = query(
            collection(db, Collections.restaurants, restaurant.id, Collections.auditlog),
            where('createdAt', '<=', filters.to),
            where('createdAt', '>=', filters.from),
            orderBy('createdAt', 'desc')
        );
        return onSnapshot(q, snapshot => {
            const data = fromFirebaseDocs<AuditLogItem>(snapshot.docs);
            console.log('audit ', data);
            setAuditLog(data);
        });
    }, [db, filters, restaurant.id])

    const toggleVisibility = (itemId: string) => {
        setOpenData({
            ...openData,
            [itemId]: !openData[itemId]
        })
    }

    return (
        <Layout noContainer className='d-flex p-1'>
            <div>
                <h6 className="mt-4">{text.chooseDate}</h6>
                <div className="center">
                    <div className="me-2 flex-1">{text.from}</div>
                    <input type="date" className="form-control flex-2"
                        value={filters.from ? dateToUnixSting(filters.from) : ''}
                        onChange={e => setFilters({ ...filters, from: e.target.valueAsDate || initDateFrom })} />
                </div>
                <div className="center mb-4">
                    <div className="me-2 flex-1">{text.to}</div>
                    <input type="date" className="form-control flex-2"
                        value={filters.to ? dateToUnixSting(filters.to) : ''}
                        onChange={e => setFilters({ ...filters, to: e.target.valueAsDate || initDateTo })} />
                </div>

                <SkatteverketXMLExport from={filters.from} to={filters.to} auditLog={auditLog} />
            </div>

            <div className='p-5 flex-1'>
                <table className='table table-dark table-striped'>
                    <thead>
                        <tr>
                            <th>{text.date}</th>
                            <th>{text.type}</th>
                            <th>{text.user}</th>
                            <th>{text.operations}</th>
                            <th>{text.data}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {auditLog.map(item => <tr key={item.id}>
                            <td>{dateTimeToUnixSting(item.createdAt)}</td>
                            <td>{item.type}</td>
                            <td>{item.userEmail || '-'}</td>
                            <td>
                                <button className='btn text-primary' onClick={() => toggleVisibility(item.id || '')}>
                                    <i className='bi bi-eye' />
                                </button>
                            </td>
                            <td>
                                {openData[item.id || '']
                                    ? <pre>
                                        {JSON.stringify(item.data, null, 2)}
                                    </pre>
                                    : '...'
                                }
                            </td>
                        </tr>)}
                    </tbody>

                </table>
            </div>
        </Layout>
    )
}
